import React, { useCallback, useEffect, useState } from 'react';
import { Wrapper } from './styles';
import { Menu, simpleScrollTo } from '../../../components/Menu';
import { usePages } from '../../../hooks/Pages';
import FooterLayout from '../../Footer';
import { useAuth } from '../../../hooks/Auth';
import PopupComponent from '../../../components/PopupContainer';
import Helmet from 'react-helmet';
import Particles from 'react-particles-js';
import ImageSliderBackground from '../../../components/ImageBackgroundSlider';
import { useParams } from 'react-router-dom';
import { useConfiguration } from '../../../hooks/Configuration';
import api from '../../../services/api';
import Loader from '../../DashboardPages/core/components/Loader';
import { hash, urlWebsite } from '../../../config';
import { useSocket } from '../../../hooks/Socket';
import { useVideo } from '../../../hooks/Video';
import FormContainerUpdate from '../../SignUpUpdate/FormContainer';
import { useModal } from '../../../hooks/Modal';
import { addDays } from 'date-fns/esm';
import { useToast } from '../../../hooks/Toast';
import { loadFromJson } from '../../../utils/loadFromJson';
import { useLanguage } from '../../../hooks/Language';
import { useLoading } from '../../../hooks/LoadingHook';
import { FaFacebook, FaInstagramSquare, FaSpotify, FaTiktok, FaYoutube } from 'react-icons/fa';

interface IParams {
  projectHash: string;
  eventHash: string;
}

interface IMenu {
  title: string;
  target: string;
  targetID: string;
  fluid: number;
  external: number;
  status: number;
}

const DefaultLayout: React.FC = ({ children }) => {
  const page = window.location.pathname !== '/';
  const { menu, setMenu, prepareMenu } = usePages();

  const { token, user, handleApiErrors, signOut } = useAuth();
  const { configuration, setConfiguration } = useConfiguration();

  const [loading, setLoading] = useState(false);
  const [ready, setReady] = useState(false);

  const { addModal, removeModal } = useModal();

  const { addToast } = useToast();


  const { projectHash, eventHash } = useParams<IParams>();



  const { setAvailableLanguages } = useLanguage();

  const [slides, setSlides] = useState<Array<JSX.Element>>([]);

  const { addLoading, removeLoading } = useLoading();







  const load = async (projectHashValue) => {
    // setLoading(true);

    try {


      let data: Record<string, any> = {};

      const project = await loadFromJson({ hash: hash, project_id: projectHashValue, table: 'Project' });

      if (project?.[0]) {
        data = project[0];

        const event = await loadFromJson({ hash: hash, project_id: data.url, table: 'Event' });
        if (event?.[0]) {


          data.current_event_id_response = { ...event[0] };
        }
        else {

          const response = await api.get(eventHash ? `/get-project-content/${projectHashValue}/${eventHash}` : `/get-project-content/${projectHashValue}`);
          data = response?.data;
        }
      }
      else {

        const response = await api.get(eventHash ? `/get-project-content/${projectHashValue}/${eventHash}` : `/get-project-content/${projectHashValue}`);

        data = response?.data;
      }



      setConfiguration(data);



      if (data?.active_pages?.length > 0) {

        prepareMenu({ eventHash, projectHash: projectHashValue, active_pages: data?.active_pages });
      }



      setLoading(false);
      setReady(true);
      setTimeout(() => { removeLoading('loading'); }, 1000)
    }
    catch (err) {

      handleApiErrors(err);
      setLoading(false);
      setTimeout(() => { removeLoading('loading'); }, 1000)

    }
  }

  const hashFragment = window.location.hash;

  useEffect(() => {


    if (hashFragment) {
      setTimeout(() => { simpleScrollTo(hashFragment?.replace('#', '')); }, 500)
    }

  }, [hashFragment])

  const loadConfig = async () => {
    addLoading({ title: 'Carregando', key: 'loading' })
    let data: Record<string, any> = {};

    const item = await loadFromJson({ hash: hash, project_id: 'global', table: 'Global' })


    if (!item) {
      const config = await api.get('/get_platform');

      data = config.data;
    }
    else {
      data = item?.[0] || {}
    }


    if (data.slides) {
      const slideJSX: Array<JSX.Element> = [];

      data.slides.map(s => {
        slideJSX.push(s.link_url ? <a href={s.link_url}><img src={`${urlWebsite}/${s.url}`} style={{ width: '100%' }} /></a> : <img src={`${urlWebsite}/${s.url}`} style={{ width: '100%' }} />)
      })

      setSlides(slideJSX);

      if (data?.popup_status === 'yes' && data?.popup_images && data?.popup_images?.length > 0) {

        if (user && user.updated_information !== 'yes' && user.profile === 2) {

        }
        else {
          addModal({
            key: 'popup',
            theme: 'whiteModalMedium',
            title: ' ',
            content: <><div style={{ width: '100%', maxWidth: '700px', margin: '0px auto' }}>

              {data?.popup_images.map(popup => {
                return <img src={`${urlWebsite}/${popup.url}`} style={{ width: '100%', maxWidth: '700px', margin: '0px auto' }} />

              })}

            </div></>
          })

        }


      }

    }

    const menu: Array<IMenu> = [];

    try {
      data?.menu.map((m: IMenu) => {
        m.fluid = parseInt(m.fluid.toString(), 10);
        m.status = parseInt(m.status.toString(), 10);
        m.external = parseInt(m.external.toString(), 10);

        menu.push(m);
      })

      setMenu(menu);

    }
    catch (err) {
      console.log('Error on define Menu');
    }


    setAvailableLanguages(data?.languages || []);
    data.default_project = 'jornada-rtc-2024'

    if (data?.default_project && !projectHash) {
      load(data?.default_project)
    }
    else {
      load(projectHash);
    }

  }

  useEffect(() => {
    loadConfig();


  }, [projectHash])


  useEffect(() => {

    if (user && !user.last_login) {
      signOut();
      window.location.reload();
    }

    if (user && user?.last_login) {

      const checkDate = addDays(new Date(), -5);
      const userDate = new Date(user.last_login);
      if (checkDate > userDate) {
        addToast({ title: 'Seu acesso expirou, faça seu login novamente', type: 'info' });

        setTimeout(() => {
          signOut();
          window.location.reload();
        }, 2000);
        return;
      }


    }

    {/*   if (user && user?.profile === 2 && user?.updated_information !== 'yes' && user?.updated_information !== 'no') {
      signOut();
      window.location.reload();
      return;
    }
  */}



  }, [user]);



  const indexOf = window?.location?.pathname.indexOf('dashboard');

  return (
    <>
      {loading && <Loader message='Carregando' />}
      <Wrapper background_color={indexOf >= 0 ? '' : configuration.background_color} background={`${urlWebsite}/${configuration.background}`}>

        <Menu menuList={menu} fixedMenu={false} />
        {ready && <>
          {children}

          <PopupComponent />


        </>}
        <div className='column containerPad' style={{ width: '100%', zIndex: 10, background: '#005389' }}>

          <h2 className='titleElement2 computerOnly' style={{ color: '#fff' }}>SIGA A RTC NAS REDES SOCIAIS E NÃO PERCA <br />NENHUMA NOVIDADE DA 3ª JORNADA DA RTC</h2>

          <h2 className='titleElement2 mobileOnly' style={{ color: '#fff', maxWidth: '300px', margin: '0px auto', textAlign: 'center' }}>SIGA A RTC NAS REDES SOCIAIS E NÃO PERCA NENHUMA NOVIDADE DA 3ª JORNADA DA RTC</h2>


          <div className='row gap'>
            <a href="https://www.facebook.com/redetecnicacoop" target='_BLANK'><FaFacebook style={{ color: '#fff', width: '50px', height: '50px', cursor: 'pointer' }} /></a>

            <a href="https://www.instagram.com/redetecnicacooperativa/" target='_BLANK'><FaInstagramSquare style={{ color: '#fff', width: '50px', height: '50px', cursor: 'pointer' }} /></a>

            <a href="https://www.youtube.com/RedeTecnicaCooperativaRTC" target='_BLANK'><FaYoutube style={{ color: '#fff', width: '50px', height: '50px', cursor: 'pointer' }} /></a>


            <a href="https://open.spotify.com/show/11U2b1ValE2c1Pj3gngpnq" target='_BLANK'><FaSpotify style={{ color: '#fff', width: '50px', height: '50px', cursor: 'pointer' }} /></a>


          </div>





        </div>
      </Wrapper>
      <FooterLayout />
      {configuration?.fixed_image_header && <img src={`${urlWebsite}/${configuration.fixed_image_header}`} className="fixedBottom" />}

      <Helmet>
        {configuration?.title && projectHash && <title>{`${configuration.title} `}</title>}

      </Helmet>

    </>
  );
};

export default DefaultLayout;
